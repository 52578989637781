import { Component, Vue } from 'vue-property-decorator';
import CustomsHandlingItem from '@/components/CustomsHandlingItem.vue.html';

import * as CustomsHandlingController from '@/api/CustomsHandlingController';
import { SearchResultViewModel } from '../api/viewmodels/SearchResultViewModel';

@Component({
    components: {
        CustomsHandlingItem
    },
})
export default class Home extends Vue {
    result: SearchResultViewModel[] = [];
    panel: number | undefined = 0;
    drawer = null;
    reference = '';
    customer = '';
    loading = false;

    search() {
        this.result = [];
        if (this.loading || (!this.reference && !this.customer)) {
            return;
        }
        
        this.loading = true;
        CustomsHandlingController.search(this.reference, this.customer)
            .then(response => {
                if (response.data !== undefined && response.data.length > 0) {
                    this.result = response.data;

                    if (response.data.length == 1) {
                        this.panel = 0;
                    } else {
                        this.panel = undefined;
                    }                    
                }
                this.loading = false;
            }).catch((error) => {
                this.loading = false;
            });
    }
}