import { Component, Vue } from 'vue-property-decorator';
import { LoginResultViewModel } from '../api/viewmodels/LoginResultViewModel';
import { LoginViewModel } from '../api/viewmodels/LoginViewModel';
import * as AccountController from '../api/AccountController';
import { ValidationObserver } from 'vee-validate';

@Component({})
export default class Login extends Vue {
    showPassword = false;
    username = '';
    password = '';
    loggingIn = false;

    loginResult: LoginResultViewModel | null = null;

    login() {
        if (this.loggingIn) {
            return;
        }

        var observer = this.$refs.observer as InstanceType<typeof ValidationObserver>;
        observer.validate().then((success: boolean) => {
            if (!success) {
                return;
            }

            this.loggingIn = true;

            let loginVm: LoginViewModel = {
                username: this.username,
                password: this.password
            }
            AccountController.login(loginVm).then((response) => {
                if (response.data.success) {
                    this.$store.commit('setLoggedIn');
                    this.$router.push({
                        path: this.$route.query.next as string || '/'
                    })
                }
                else {
                    this.loginResult = response.data;
                }

                this.loggingIn = false;
            }).catch(error => {
                this.loggingIn = false;
            });
        });
    }
}